


import { Options, Vue } from 'vue-class-component';
import MainCenter from '@/components/MainCenter.vue'; // @ is an alias to /src

import {sendEmail} from '../email'
@Options({
  components: {
    MainCenter: MainCenter,
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: '',
        subject: ''
      }
    }
  },
  methods: {
    onClickMethod () {
      sendEmail(this.form);
    }
  }
})

export default class Home extends Vue {}
