import { SendEmailCommand, SESClient }  from "@aws-sdk/client-ses";
import AWS from 'aws-sdk'

export function sendEmail(formParams:any) {
  return new Promise((resolve, reject) => {
    const REGION = "us-east-1";
    const sesClient = new SESClient({
      region: REGION,
      credentials: creds
    });

    const params = {
      Destination: {
        ToAddresses: [
          "alaynacraft@gmail.com"
        ],
      },
      Message: {
        Body: {
          Text: {
            Charset: "UTF-8",
            Data: formParams.message,
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: formParams.subject,
        },
      },
      Source: "no-reply@mail.atchafalayaatv.com", // SENDER_ADDRESS
      ReplyToAddresses: [
        formParams.name + ' <' + formParams.email + '>'
      ],
    };

    const sendEmailOnClick = async () => {
      try {
        const data = await sesClient.send(new SendEmailCommand(params));
        alert("Email Sent!");
        (document.getElementById("contact-form") as HTMLFormElement).reset();
        return data; // For unit tests.
      } catch (err) {
        alert("Failed to send..");
	console.log(err);
      }
    };

    sendEmailOnClick();

  });
}
